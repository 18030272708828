.App {
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #27af92;
}
.header a {
  color: #fff;
  text-decoration: none;
}

.cmscolor {
  color: #fff;
  background-color: #27af92 !important;
  border-color: #27af92 !important;
}

.react-tagsinput-input {
  width: 100%;
}

.auth-options {
  width: 100%;
}

.k-grid a {
  color: #007bff !important;
  text-decoration: none !important;
  background-color: transparent !important;
}

.k-grid-header th {
  font-weight: 700 !important;
}

.gridcontainer {
  max-width: 95% !important;
}

.k-block ::selection,
.k-breadcrumb ::selection,
.k-card ::selection,
.k-chart ::selection,
.k-chat ::selection,
.k-combobox ::selection,
.k-dateinput ::selection,
.k-datepicker ::selection,
.k-datetimepicker ::selection,
.k-drawer ::selection,
.k-dropdown ::selection,
.k-dropdowntree ::selection,
.k-flatcolorpicker ::selection,
.k-maskedtextbox ::selection,
.k-mediaplayer ::selection,
.k-multiselect ::selection,
.k-notification ::selection,
.k-numerictextbox ::selection,
.k-pager-wrap ::selection,
.k-pane-wrapper ::selection,
.k-panel ::selection,
.k-panelbar ::selection,
.k-pdf-viewer ::selection,
.k-progressbar ::selection,
.k-rating ::selection,
.k-scheduler ::selection,
.k-sparkline ::selection,
.k-splitter ::selection,
.k-spreadsheet ::selection,
.k-stockchart ::selection,
.k-text-selection ::selection,
.k-timepicker ::selection,
.k-view ::selection,
.k-window ::selection,
div.k-scrollview ::selection,
kendo-scrollview.k-scrollview-wrap ::selection,
kendo-scrollview.k-scrollview ::selection {
  background-color: #6c757d !important;
  color: #fff;
}

.pass-wrapper {
  position: relative;
  display: flex;
}

.pass-wrapper i {
  position: absolute;
  top: 8px;
  right: 10px;
}
.pass-wrapper i:hover {
  color: #27af92;
  cursor: pointer;
}
.pass-wrapper .form-control.is-invalid {
  padding-right: 3.5rem;
  background-position: right 2rem center;
}
.pass-wrapper .form-control {
  padding-right: 2rem;
}
.loding_autoheader {
  position: fixed;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.1);
  left: 0px;
  top: 0px;
  z-index: 999999;
}

.loding_autoheader img {
  position: absolute;
  top: 50%;
  left: 47%;
}

#Customer.is-invalid {
  background-position: right 1rem center;
}

.k-grid-header .k-header > .k-link {
  word-break: break-all;
  white-space: break-spaces;
}

.k-grid td {
  word-break: break-all;
}
